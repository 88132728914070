<template>
  <div>
    <el-table :data="commentator" border>
      <el-table-column prop="id" label="序号" width="60"> </el-table-column>
      <el-table-column label="头像" width="150">
        <template slot-scope="scope">
          <el-image :src="scope.row.base64">
          </el-image>
        </template>
      </el-table-column>
      <el-table-column label="职位" width="160">
        <template slot-scope="scope">
          <span v-if="!scope.row.isadmin"
            >ASG常驻解说<i style="color: gold" class="el-icon-s-check"></i
          ></span>
          <span v-else>解说管理员</span>
        </template>
      </el-table-column>
      <el-table-column prop="chinaname" label="姓名" width="auto">
      </el-table-column>
      <el-table-column label="邮箱" width="350">
        <template #default="{row}">
          {{ row.email || '-' }}
        </template>
      </el-table-column>
      <el-table-column label="身份" width="150">
        <template slot-scope="scope">
             <span v-if="scope.row.isadmin"><i class="el-icon-s-custom"></i>解说组长</span>
             <span v-else><i class="el-icon-user-solid"></i>普通用户</span>
        </template>
      </el-table-column>
      <el-table-column label="操作" width="150" align="center">
        <template #default="{row}">
          <el-button style="color:#f40" type="text" size="mini" @click="refuseUser(row)">劝退</el-button>
        </template>
      </el-table-column>
    </el-table>
  </div>
</template>

<script>
import { getUserRoles } from "@/api/schedule/index";
import { setRole } from "@/api/home/index.js"
export default {
  name:'CommentaryManager',
  mounted() {
    this.initGetCommentary();
  },
  data() {
    return {
      commentator: [],
    };
  },
  methods: {
    initGetCommentary() {
      let params = {
        opname: "Commentator",
      };
      getUserRoles(params)
        .then((res) => {
          this.commentator = res.data;
        })
        .catch((err) => {
          console.log(err);
        });
    },
    refuseUser(row){
      this.$confirm(`您确定将解说${row.chinaname}劝退吗？, 一旦操作无法恢复，是否继续?`, '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          setRole(row.id,"").then(()=>{
             this.$message.success("劝退成功！");
             this.initGetCommentary();
          })
          .catch((err)=>{
            this.$message.error(err);
          })
        }).catch(() => {});
    
    }
  },
};
</script>

<style scoped lang="less"></style>
