import { render, staticRenderFns } from "./commentator.vue?vue&type=template&id=f211b0cc&scoped=true"
import script from "./commentator.vue?vue&type=script&lang=js"
export * from "./commentator.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "f211b0cc",
  null
  
)

export default component.exports